<script>
import "./plugins/jquery.datetimepicker.css"
import "./plugins/jquery.datetimepicker.js"

export default {
  props: ['value', 'dateType'],
  mounted: function () {
    setTimeout(()=>{ this.init() },100);
  },
  methods:{
    init(){
  		var option = {
        theme:'dark',
        format:'m/d/Y H:i',
        closeOnDateSelect:false,
        step:5,
  		}
      if(this.value) this.$emit('input', this.value.dates("MM/DD/YYYY HH:mm"))
    	$(this.$el).datetimepicker(Object.assign(option,this.config||{})).change((e)=>{
        this.$emit('input', e.target.value)
        $(this.$el).valid()
  		});
    }
  },
	watch: {
		value: function (v) {
      if(!v) return $(this.$el).datetimepicker({value: ''});
			$(this.$el).datetimepicker({value:v.dates("MM/DD/YYYY HH:mm")})
		}
	}
}
</script>

<template>
  <input type="text" class="form-control">
</template>
